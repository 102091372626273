<template>
  <v-app-bar elevate-on-scroll app color="navColor" inverted-scroll>
    <div class="container py-4">
      <div class="row">
        <div class="col-lg-12">
          <v-toolbar-items>
            <img
              width="110px"
              src="../../assets/photo_2023-04-01_22-56-27.jpg"
            />
            <v-spacer></v-spacer>
            <v-btn @click="toggleDrawer" class="hidden-md-and-up mt-2" icon>
              <v-icon>mdi-menu</v-icon>
            </v-btn>
            <v-btn
              class="hidden-sm-and-down mt-2 py-1"
              active-class="aa"
              color=""
              to="/"
              text
            >
              {{ $t("home.hero.home") }}
            </v-btn>
            <v-btn
              class="hidden-sm-and-down mt-2 py-1"
              active-class="aa"
              color=""
              to="/about"
              text
            >
              {{ $t("home.hero.aboutus") }}
            </v-btn>
            <v-btn
              class="hidden-sm-and-down mt-2 py-1"
              active-class="aa"
              color=""
              to="/projects"
              text
            >
              {{ $t("home.hero.projects") }}
            </v-btn>

            <v-btn
              class="hidden-sm-and-down mt-2 py-1"
              active-class="aa"
              color=""
              to="/contact"
              text
            >
              {{ $t("home.hero.contactus") }}
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn class="mt-2" icon v-on="on">
                  <v-icon>mdi-web</v-icon>
                </v-btn>
              </template>
              <v-list v-model="$i18n.locale">
                <v-list-item
                  :disabled="$i18n.locale === 'en'"
                  @click="changLang"
                >
                  <v-list-item-title>English</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :disabled="$i18n.locale === 'ar'"
                  @click="changLang"
                >
                  <v-list-item-title>عربي</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn class="mt-2" @click="setTheme" icon>
              <v-icon>mdi-invert-colors</v-icon>
            </v-btn>
          </v-toolbar-items>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "CoreAppBar",

  computed: {
    font: {
      get() {
        return this.$store.state.font;
      },
      set(value) {
        this.$store.commit("setFont", value);
      },
    },
  },
  methods: {
    changLang() {
      if (this.$i18n.locale === "en") {
        this.$i18n.locale = "ar";
        this.$vuetify.rtl = true;
        this.font = "Noto Naskh Arabic";
      } else {
        this.$i18n.locale = "en";
        this.$vuetify.rtl = false;
        this.font = "Montserrat";
      }
    },
    ...mapMutations(["toggleDrawer"]),

    setTheme() {
      if (this.$vuetify.theme.dark) {
        return (this.$vuetify.theme.dark = false);
      } else {
        return (this.$vuetify.theme.dark = true);
      }
    },
  },
};
</script>

<style>
.aa {
  color: #054467 !important;
  font-weight: bold !important;
}
</style>
